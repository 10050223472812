<template>
  <header class="header" id="site-header">
    <div class="header__container">
      <router-link to="/" class="header__logo">
        <LogoLeanpassion />
      </router-link>
      <div class="header__motto">
        <img
          src="../assets/MottoLeanpassion.png"
          alt="Great Companies Happy People"
        />
      </div>
    </div>
  </header>
</template>

<script>
import LogoLeanpassion from '@/assets/LogoLeanpassion'

export default {
  name: 'MainHeader',

  components: {
    LogoLeanpassion,
  },
}
</script>

<style lang="scss">
.header {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 7rem;
    padding: 0 2rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      height: unset;
      padding: 2rem 1rem;
    }
  }

  &__logo {
    flex: auto;
    max-width: 17rem;

    svg {
      color: var(--color-text);
      width: 17rem;
      display: block;
    }
  }

  &__motto {
    flex: 1;
    max-width: 19rem;

    @media screen and (max-width: 768px) {
      margin-top: 2rem;
    }
  }
}
</style>
