<template>
  <section class="site__section section--summary">
    <h3 class="section__summary-title">
      {{ summary.title }}
    </h3>
    <div class="section__summary-image">
      <VideoPlayer :video="video" :poster="poster" />
    </div>
    <h3 class="section__summary-subtitle">
      {{ summary.subtitle }}
    </h3>
    <ol class="section__summary-list">
      <li
        v-for="(item, i) of summary.list"
        :key="`summary-${i}`"
        class="section__summary-list-item"
        v-html="item"
      />
    </ol>
    <div class="section__summary-description">
      {{ summary.description }}
    </div>
  </section>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'SectionSummary',

  props: {
    summary: {
      type: Object,
      default: () => {},
    },
    video: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
  },

  methods: {
    image(path) {
      return require(`@/assets/${path}`)
    },
  },

  components: {
    VideoPlayer,
  },
}
</script>

<style lang="scss">
@use '@/style/_lists.scss';

.section {
  &--summary {
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    max-width: 46.5rem;

    @media screen and (max-width: 768px) {
      padding: 0rem 2rem;
    }

    .plyr {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__summary {
    &-title,
    &-subtitle {
      margin: 3rem 0;
      text-align: center;
      font-size: var(--font-size-title-xl);
      font-weight: 700;
      line-height: 1.2;
    }

    &-image {
      border-radius: var(--border-radius);
      overflow: hidden;
      margin: 0 -3rem 1rem;
      box-shadow: var(--box-shadow-deep);

      @media screen and (max-width: 768px) {
        margin: 0 -1rem 1rem;
      }
    }

    &-list {
      @include lists.list-with-check-mark;
    }

    &-description {
      margin: 2rem 0;
      font-weight: 800;
      font-size: var(--font-size-lg);
    }
  }
}
</style>
