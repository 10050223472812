const webinar = {
  title: 'Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe',
  headline:
    'Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe?',
  lead: `Aktualne wyzwania menedżerów w branży usługowej dotyczą zwiększenia
  zaangażowania pracowników i efektywności procesów oraz wzrostu biznesu i zdolności organizacji do adaptacji.
  <br/><strong>Czym wyróżnia się Skuteczny Lider 2022?</strong>`,
  video: 'video/trailer.mp4',
  poster: 'video/trailer-poster.jpg',
  video_time: '50 min',
  video_subtitle: 'Radek Drzewiecki, CEO Leanpassion Group',
  icons: [
    {
      icon: 'icon-leadership.png',
      title: 'Skuteczny<br/>Lider',
      description: `Współczesny lider rozumie perspektywę pracowników, przewodzi w sposób
      świadomy i skutecznie zarządza. Pracuje nad sensem istnienia, buduje
      poczucie przynależności do zespołu, stawia na selekcję. Tworzy
      środowisko pracy, w którym każdy każdego dnia chętnie rozmawia o celach
      i wynikach.`,
    },
    {
      icon: 'icon-conference.png',
      title: 'Ekspert<br/>Zarządzania',
      description: `Przyszłość należy do firm, które potrafią nauczyć swoich liderów
      nieimprowizowanego, opartego na świadomym podejściu do ludzi
      przywództwa. Zarządzający mogą wpłynąć na efektywność organizacji poprzez włączenie liderów w jej współtworzenie.`,
    },
    {
      icon: 'icon-training.png',
      title: 'Tylko praktyczna<br/>wiedza',
      description: `Zobacz, w jaki sposób zdefiniować portfolio procesów w organizacji, jak określić poziom efektywności tych procesów i stworzyć organizację, w której ludzie sami chcą mierzyć procesy. Zwiększ swoją skuteczność menedżerską.`,
    },
  ],
  summary: {
    title: 'Zobacz zwiastun',
    image: 'video/poster-video-trailer.jpg',
    subtitle: 'Czego nauczysz się w trakcie?',
    list: [
      `Kim jest Skuteczny Lider AD2022`,
      `Dlaczego empatia i przywództwo to paradoksalne połączenie `,
      `Jak zdefiniować portfolio procesów w organizacji`,
      `W jaki sposób określić poziom efektywności procesów`,
      `Jak stworzyć organizacje, w której ludzie sami chcą mierzyć procesy`,
      `Jak dzięki eliminacji marnotrawstwa generować oszczędności w firmie`,
      // `...i dzięki temu <em>generować oszczędności</em> w firmie `,
    ],
    description:
      'Nauczysz się przeprowadzać refleksję strategiczną i tożsamościową zespołów. Dowiesz się jak jednocześnie: zwiększać efektywność i zapobiegać rotacji. Zrozumiesz czym się różni przywództwo od zarządzania',
  },

  speakers: {
    title: 'Kilka słów o mnie',
    list: [
      {
        name: 'Radek Drzewiecki',
        position: 'CEO Leanpassion Group',
        bio: `<strong>Moją misją jest inspirowanie ludzi, by nie marnowali swojego życia, a firm do stwarzania warunków, w których wszyscy mogą wygrywać.</strong>
        <p>Od ponad 20 lat zajmuję się transformacjami lean. Doświadczenie zdobywałem pracując w firmach motoryzacyjnych oraz pełniąc rolę LEAN Managera w GE. Następnie już w ramach Leanpassion współpracowałem między innymi z: Raiffeisen Bank Polska, Infosys, MAN Accounting Center, mLeasing, Europejski Fundusz Leasingowy, Amway, Credit Agricole, P&G, PZU, Allianz, LOT, PKP Intercity, Solaris, Fresh Logistics, Capgemini, Adamed, Mars, Medicover, EMC. Ukończyłem The Polish Open University (tytuł MBA), Politechnikę Wrocławską, a także Porsche Lean Akademie w Stuttgarcie oraz kurs w Shingijutsu Global Co.</p>
        <p>Wraz ze swoim zespołem stworzyłem autorski, 5-krokowy proces budowania Strategii LEAN oraz model przywództwa Leadership 734. A całość podsumowałem w książce “Strategia Lean – Dlaczego w WIELKICH FIRMACH ludzie nie mogą doczekać się PONIEDZIAŁKÓW?”. Jestem współzałożycielem start upu Sherlock Waste, aplikacji zmieniającej frustrację w innowację oraz firmy serwisu streamingowego LEANOVATICA.</p>
        <p>Prywatnie jestem tatą trójki fantastycznych dzieci: Szymona, Amelki i Franka oraz oddanym kibicem Realu Madryt.</p>
        <p>Moje motto to: <strong>„WIELKIE FIRMY, SZCZĘŚLIWI LUDZIE”</strong>.</p>`,
        image: 'photos/radek-drzewiecki-2.png',
      },
    ],
  },

  companies: {
    title: 'Na spotkaniach live gościliśmy między innymi',
    list: [
      {
        image: 'guests-cover-1.jpg',
        name: 'Maciej Noga',
        description: 'Przedsiębiorca i Inwestor HRTecg, Grupa Pracuj',
      },
      {
        image: 'guests-cover-9.jpg',
        name: 'Daria Abramowicz',
        description: 'Psycholog sportowy',
      },
      {
        image: 'guests-cover-2.jpg',
        name: 'Łukasz Kruczek',
        description: 'Trener polskiej kadry skoczkiń narciarskich',
      },
      {
        image: 'guests-cover-3.jpg',
        name: 'Maciej Maj',
        description: 'Założyciel agencji badawczej Openfield',
      },
      {
        image: 'guests-cover-5.jpg',
        name: 'Paweł Gąciarski',
        description: 'Director Mechanical Systems Department Dürr Poland',
      },
      {
        image: 'guests-cover-4.jpg',
        name: 'Joanna Sowa',
        description: 'Starszy specjalista ds. doskonalenia procesów',
      },
      {
        image: 'guests-cover-6.jpg',
        name: 'Grzegorz Rutkowski',
        description: 'Dyrektor Generalny Habasit Polska',
      },
      {
        image: 'guests-cover-7.jpg',
        name: 'Andrzej Piotrowski',
        description: 'Dyrektor Sprzedaży Columbus Energy',
      },
      {
        image: 'guests-cover-8.jpg',
        name: 'Cezary Raczyński',
        description: 'CEO mLeasing',
      },
    ],
  },

  credentials: {
    title: 'Historie o naszym wydarzeniu',
    list: [
      {
        text: 'Za każdym razem jak u was jestem to wychodzę w szoku że dajecie radę być jeszcze bardziej profesjonalni. Dzięki za zaproszenie',
        person: 'Maciej Maj',
        position: 'CEO - Openfield sp. z o.o.',
      },
      {
        text: 'Super gość! Super prowadzący! Super webinar... ile już tych S...? Życzę kolejnych sukcesów!',
        person: 'Marek Czeszejko',
        position: 'Operations Manager w Forankra Poland',
      },
      {
        text: 'Luźno, profesjonalnie, konkretnie!',
        person: 'Konrad Pilarski',
        position: 'Dyrektor Sprzedaży i Marketingu w Enpulse',
      },
      {
        text: 'Program kupiony, czekam z niecierpliwością.',
        person: 'Grażyna Pławska',
        position: 'Uczę młodych menedżerów skutecznego zarządzania',
      },
      {
        text: 'Bardzo wartościowy webinar',
        person: 'Krzysztof Liszkowicz',
        position: 'Managing Direcotr Pravda S.A., BNI Member',
      },
      {
        text: 'Świetny webinar',
        person: 'Grażyna Lewicka',
        position: 'Właściciel w GOOD MINDSET',
      },
    ],
  },
}

export default webinar
