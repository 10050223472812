<template>
  <div class="video__loader">
    <span
      class="video__loader-spinner"
      :class="{ 'is--loading': progress }"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'VideoLoader',
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.video__loader {
  display: block;
  width: 20rem;
  height: 20rem;
  position: relative;

  &-spinner {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border: 7px solid var(--color-spinner-track);
    border-right-color: var(--color-main);
    border-radius: 100%;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(0deg);

    &.is--loading {
      animation-name: spinner;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
}

@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(-50%, -50%, 0) rotate(180deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
</style>
