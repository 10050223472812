<template>
  <footer class="site__footer">
    <div class="site__footer-container">
      <span>&copy; 2022 Leanpassion sp. z o.o.</span>
      <router-link to="/warunki-korzystania">warunki korzystania</router-link>
      <router-link to="/polityka-prywatnosci">polityka prywatności</router-link>
    </div>
    <div class="site__footer-container footer--icons">
      <a href="https://www.linkedin.com/company/leanpassion" target="_blank">
        <IconLinkedin />
      </a>
      <a href="https://www.facebook.com/Leanpassion/" target="_blank">
        <IconFacebook />
      </a>
      <a href="https://www.youtube.com/c/LeanpassionYT" target="_blank">
        <IconYoutube />
      </a>
    </div>
  </footer>
</template>

<script>
import IconLinkedin from '@/assets/icons/icon-linkedin'
import IconFacebook from '@/assets/icons/icon-facebook'
import IconYoutube from '@/assets/icons/icon-youtube'

export default {
  name: 'MainFooter',

  components: {
    IconLinkedin,
    IconFacebook,
    IconYoutube,
  },
}
</script>

<style lang="scss">
.site {
  &__footer {
    background: var(--color-bg);
    padding: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-sm);
    color: var(--color-text-light);

    @media screen and (max-width: 768px) {
      flex-direction: column;
      font-size: var(--font-size);
    }

    span {
      display: inline-block;

      @media screen and (max-width: 768px) {
        display: block;
        width: 100%;
        padding-bottom: 0.75rem;
      }
    }

    a {
      display: inline-block;
      margin-left: 1rem;
      position: relative;
      color: var(--color-text-light);
      transition: var(--transition);
      padding-left: 1rem;

      &:first-of-type {
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
      }

      &:hover {
        color: var(--color-main);
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 4px;
        background: var(--color-main);
        border-radius: 100%;
      }
    }

    &-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      max-width: 60%;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        flex-wrap: wrap;
      }

      &.footer--icons {
        justify-content: flex-end;

        @media screen and (max-width: 768px) {
          margin-top: 3rem;
        }

        a {
          padding-left: 0;

          svg {
            width: 1.5rem;
            fill: var(--color-text-light);
            transition: var(--transition);

            @media screen and (max-width: 768px) {
              width: 2rem;
            }
          }

          &:hover {
            svg {
              fill: var(--color-main);
            }
          }

          &::before {
            display: none;
          }
        }
      }
    }
  }
}
</style>
