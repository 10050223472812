<template>
  <div class="video__container" :class="videoClass">
    <vue-plyr v-if="source === 'local'">
      <video
        playsinline
        controls
        class="video__player"
        :poster="publicPath + poster"
      >
        <source :src="publicPath + video" type="video/mp4" />
        Your browser doesn't support HTML5 video tag.
      </video>
    </vue-plyr>

    <iframe
      v-if="source === 'vimeo'"
      :src="`https://player.vimeo.com/video/${video}`"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      class="video__player"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',

  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },

  props: {
    video: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
    videoClass: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: 'local',
    },
  },

  mounted() {},
}
</script>

<style lang="scss">
.video {
  &__container {
    display: block;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    border-radius: var(--border-radius);

    &.video--bigger {
      padding-bottom: 67.5%;
    }
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
