const webinar = {
  title:
    'Zarejestrowałeś się na sesję masterclass - Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe',
  headline:
    '<small>Zarejestrowałeś się na sesję masterclass</small>&ldquo;Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe&rdquo;',
  lead: `O wybranej przez siebie godzinie, na swoją skrzynkę mailową otrzymasz link do sesji.`,
  video: 'video/trailer.mp4',
  poster: 'video/poster-video-trailer.jpg',
  section_info: {
    title: 'Głodny wiedzy już teraz?',
    text: 'Przejdź do naszego bloga. Znajdziesz w nim wiele wartościowych treści, które pomogą rozwinąć Twoją firmę.',
  },
  section_calendar: {
    title: 'Ustaw przypomnienie!',
    text: '<strong>Skorzystaj jak najwięcej z sesji.</strong><span>Dodaj wydarzenie do kalendarza.</span>',
  },
}

export default webinar
