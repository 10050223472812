<template>
  <div class="site__container">
    <MainHeader />
    <div class="site__content" v-if="content">
      <h1 class="site__content-title">
        {{ content.title }}
      </h1>
      <div v-html="content.text" />
    </div>
    <MainFooter />
  </div>
</template>

<script>
import WarunkiKorzystania from '@/models/warunki-korzystania'
import PolitykaPrywatnosci from '@/models/polityka-prywatnosci'

import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'

export default {
  name: 'ContentView',

  data() {
    return {
      content: null,
    }
  },

  methods: {
    getContent() {
      const page = this.$route.name.replace(/\/$/, '')
      this.content =
        page === 'WarunkiKorzystania' ? WarunkiKorzystania : PolitykaPrywatnosci
    },
  },

  mounted() {
    this.getContent()
  },
  watch: {
    $route() {
      this.getContent()
    },
  },

  components: {
    MainHeader,
    MainFooter,
  },
}
</script>

<style lang="scss">
.site__content {
  padding: 2rem 3rem;

  &-title {
    display: block;
    text-align: center;
    margin: 1rem 0;
    padding: 0 0 1rem;
    font-size: var(--font-size-title-xxl);
    color: var(--color-text);
  }

  > div {
    line-height: 1.75;
  }

  h4 {
    font-size: var(--font-size-lg);
  }

  a {
    color: var(--color-action);
    font-weight: 500;
    transition: var(--transition);

    &:hover {
      color: var(--color-main);
    }
  }
}
</style>
