import { createStore } from 'vuex'
import application from './application'
import form from './form'
import consultation from './consultation'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    application,
    form,
    consultation,
  },
})
