<template>
  <form
    class="form__container"
    :id="`form-${formName}`"
    @keypress="enterPressed"
  >
    <div class="form__header">
      <IconVideo /><span>Dostępny jedynie przez limitowany czas</span>
    </div>
    <fieldset class="form__fields">
      <div class="form__fields-errors" :class="{ 'is--active': errors }">
        Musisz poprawić wymagane pola
      </div>
      <div
        v-for="(item, i) of fields"
        :key="`field-${i}`"
        class="form__fields-item"
      >
        <input
          :type="item.type"
          :name="`field-${i}`"
          v-model="form[i]"
          :pattern="item.pattern"
          :id="`${formName}-field-${i}`"
          @keypress="isValidInput($event, item.reg)"
          :placeholder="item.label"
          class="form__fields-input"
          :minlength="item.minLength"
          :maxlength="item.maxLength"
          required
        />
        <label :for="`${formName}-field-${i}`" class="form__fields-label">
          {{ item.label }}
        </label>
      </div>
      <div class="form__fields-item">
        <div class="form__fields-selector">
          <select
            name="field-date"
            class="form__fields-select scrollable"
            :class="{ 'field--error': !form.date }"
            data-chosen="null"
            v-model="form.date"
            @change="selecteDate"
          >
            <option disabled="disabled" selected="selected" value="null">
              Wybierz datę
            </option>
            <option
              v-for="(item, i) of formDates"
              :key="`date-${i}`"
              :value="item.value"
            >
              {{ item.label }}
            </option>
          </select>
        </div>
        <div class="form__fields-selector" v-if="timeSelector">
          <select
            name="field-time"
            class="form__fields-select scrollable"
            data-chosen="null"
            onchange="this.dataset.chosen = this.value"
            v-model="form.time"
          >
            <option disabled="disabled" selected="selected" value="null">
              Godzina
            </option>
            <option
              v-for="hour of timeHours"
              :key="`date-time-${hour}`"
              :value="properHour(hour)"
            >
              {{ properHour(hour) }}
            </option>
          </select>
        </div>
      </div>
      <div class="form__fields-item">
        <div class="form__fields-agrees">
          <label class="form__checkbox">
            <input type="checkbox" required="" value="gdpr-regulations" />
            <p>
              Zapoznałem się i zgadzam się z zapisami
              <router-link to="/polityka-prywatnosci/">
                polityki prywatności </router-link
              >, a swoje dane do kontaktu zostawiam świadomie i dobrowolnie. Mam
              świadomość, że mogę zażądać poprawiania swoich danych osobowych,
              ograniczenia ich przetwarzania lub usunięcia.*
            </p>
          </label>
        </div>
      </div>
      <button
        type="button"
        class="form__button"
        :class="{ 'is--busy': busy }"
        @click="validateForm"
      >
        <span v-html="!busy ? 'Zarezerwuj swój czas' : 'Trwa zapisywanie...'" />
      </button>
    </fieldset>
  </form>
</template>

<script>
import { WEBINAR_ID } from '@/helpers/http'
import { mapState } from 'vuex'
import { actionNames } from '@/store/form'
import IconVideo from '../assets/icons/IconVideo.vue'

export default {
  components: { IconVideo },
  name: 'FormReservation',

  props: {
    formName: {
      type: String,
      default: 'reservation',
    },
  },

  data() {
    return {
      formDates: this.avaliableDates(),
      timeSelector: false,
      timeHours: [...Array(24).keys()],
      errors: false,
    }
  },

  computed: {
    ...mapState({
      form: (state) => state.form.data,
      fields: (state) => state.form.fields,
      busy: (state) => state.form.progress,
      thankyou: (state) => state.form.thankyou,
    }),
  },

  methods: {
    validatePreferredTime() {
      const date = this.form.date
      const time = this.form.time

      return date && time ? true : false
    },

    validateForm() {
      if (this.busy) return
      const form = document.getElementById(`form-${this.formName}`)
      const isValid = form.checkValidity()
      const validDate = this.validatePreferredTime()

      this.errors = !isValid || !validDate ? true : false

      if (!validDate || !isValid) {
        form.classList.add('show--errors')
      } else {
        form.classList.remove('show--errors')
        this.sendForm()
      }
    },

    async sendForm() {
      const { name, date, time, ...data } = this.form
      const splitted = name.split(' ')
      const first_name = splitted[0]
      splitted.shift()
      const last_name = splitted.join(' ')

      const now = new Date()
      let properDate = date
      if (date === 'now') {
        properDate = this.formatDate(now)
      }
      const timestamp = new Date(this.formatTimestamp(properDate, time))

      const object = {
        first_name,
        last_name,
        preffered_datetime: timestamp.getTime(),
        ...data,
        start_date: date,
        start_time: time,
        webinar_id: WEBINAR_ID,
        scheduled: date === 'now' ? false : true,
      }
      const response = await this.$store.dispatch(
        `form/${actionNames.SEND}`,
        object
      )
      if (response === 200) {
        this.$router.push({
          path: `/thankyou`,
        })
      }
    },

    properHour(hour) {
      return hour.toString().padStart(2, '0') + ':00'
    },

    selecteDate(date) {
      const value = date.target.value
      date.target.dataset.chosen = value
      const item = this.formDates.find((i) => i.value === value)
      this.form.time = null

      const now = new Date()
      if (this.formatDate(now) === value) {
        const nextHour = new Date(now.setHours(now.getHours() + 1)).getHours()
        this.timeHours = [...Array(24).keys()].filter((i) => i >= nextHour)
      } else if (value === 'now') {
        this.form.time = `${now.getHours()}:00`
      } else {
        this.timeHours = [...Array(24).keys()]
      }
      this.timeSelector = item.timeSelector
    },

    isValidInput(e, reg) {
      const pattern = new RegExp(reg)
      if (!pattern.test(e.key)) return e.preventDefault()
    },

    avaliableDates() {
      const date = new Date()
      const list = [
        {
          label: 'Obejrzyj natychmiast',
          value: 'now',
          timeSelector: false,
        },
        {
          label: 'Dzisiaj, o godzinie',
          value: this.formatDate(date),
          timeSelector: true,
        },
      ]

      const tomorrow = new Date(date.setDate(date.getDate() + 1))
      list.push({
        label: this.formatNiceDate(tomorrow),
        value: this.formatDate(tomorrow),
        timeSelector: true,
      })
      const dayafter = new Date(date.setDate(date.getDate() + 1))
      list.push({
        label: this.formatNiceDate(dayafter),
        value: this.formatDate(date),
        timeSelector: true,
      })
      return list
    },

    formatNiceDate(date) {
      const options = { weekday: 'long', month: '2-digit', day: '2-digit' }
      return date.toLocaleDateString('pl-PL', options)
    },
    formatDate(date) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
      return date.toLocaleDateString('pl-PL', options)
    },
    formatTimestamp(date, time) {
      const parsed = date.split('.')
      return `${parsed[2]}-${parsed[1]}-${parsed[0]}T${time}:00`
    },

    enterPressed: function (event) {
      if (event.keyCode === 13) {
        this.validateForm()
      }
    },
  },
}
</script>

<style lang="scss">
.form {
  &__container {
    background: var(--color-bg);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    border: var(--box-border);
    overflow: hidden;

    &.show--errors {
      .field--error {
        border-color: var(--color-main-light);
      }
      input:invalid {
        border-color: var(--color-main-light);
        & + label {
          color: var(--color-main);
        }
      }
    }
  }

  &__header {
    background: var(--color-main);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
    color: var(--color-bg);

    svg {
      width: 2rem;
      max-width: 2rem;
      flex: auto;
    }

    span {
      flex: 1;
      font-weight: 600;
      font-size: 0.9375rem;
      padding-left: 0.5rem;
      line-height: 1.1;
      text-align: center;
    }
  }

  &__fields {
    border: 0;
    padding: 1.5rem;

    &-errors {
      color: var(--color-main);
      font-size: 0.825rem;
      text-align: center;
      padding: 0.25rem 0;
      margin: -0.75rem 0rem 1rem;
      border-radius: 4px;
      font-weight: 700;
      border: 1px solid var(--color-main);
      transition: var(--transition);
      max-height: 0px;
      opacity: 0;

      &.is--active {
        padding: 0.25rem 0;
        max-height: 100px;
        opacity: 1;
      }
    }

    &-item {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;
      column-gap: 1rem;
    }

    &-input,
    &-select {
      width: 100%;
      border: var(--box-border);
      border-radius: var(--border-radius-sm);
      background: var(--color-bg);
      font-size: 16px;
      font-family: var(--font-family);
      height: 3.5rem;
      padding: 1.25rem 1rem 0.25rem 1rem;
      color: var(--color-text);
      outline: none;
      transition: var(--transition);
      position: relative;

      &:focus {
        box-shadow: var(--box-shadow);
      }

      &::placeholder {
        color: transparent;
      }

      &:focus + label,
      &:not(:placeholder-shown) + label,
      &:valid + label {
        font-size: 0.6875rem;
        height: 1rem;
        top: 6px;
        opacity: 0.5;
      }
      &:invalid:not(:placeholder-shown) + label {
        color: var(--color-main);
      }
    }

    &-label {
      position: absolute;
      display: flex;
      align-items: center;
      font-size: 1rem;
      height: 3.5rem;
      top: 0%;
      padding: 0 1rem;
      transition: var(--transition);
      z-index: 9;
      opacity: 1;
      color: var(--color-text-light);
    }

    &-select {
      padding: 1rem;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      color: var(--color-text);
      font-size: 1rem;
      cursor: pointer;

      &[data-chosen='null'] {
        color: var(--color-text-light);
      }

      option {
        color: var(--color-text);
        font-size: 0.9375rem;
        padding: 2rem;

        &:disabled {
          font-size: 0.8125rem;
          color: var(--color-text-light);
        }
      }
    }

    &-selector {
      flex: 1;
      max-width: 100%;
      position: relative;

      & + & {
        max-width: 35%;

        select[data-chosen='null'] {
          font-size: 0.875rem;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 0.65rem;
        height: 0.65rem;
        border: 2px solid var(--color-text-light);
        border-width: 2px 2px 0 0;
        transform: rotate(135deg);
        transform-origin: 50% 50%;
        position: absolute;
        top: calc(50% - 0.5rem);
        right: 1.25rem;
        transition: var(--transition);
        z-index: 9;
      }
    }
  }

  &__checkbox {
    display: block;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;

      &:checked ~ p::before {
        background-color: var(--color-main);
        border-color: var(--color-main);
      }
    }

    a {
      position: relative;
      display: inline-block;
      color: var(--color-main);
      font-weight: 600;
      transition: var(--transition);

      &:hover {
        color: var(--color-action);
      }
    }
    p {
      position: relative;
      display: inline-block;
      padding: 0 0 0 1.5rem;
      margin: 0;
      font-size: 11px;
      line-height: 1.3;

      &::before {
        content: '';
        position: absolute;
        transition: var(--transition);
        width: 16px;
        height: 16px;
        top: 0;
        left: 0;
        border: 1px solid var(--border-color);
        border-radius: 3px;
      }

      &::after {
        content: '';
        position: absolute;
        transition: var(--transition);
        display: block;
        opacity: 1;
        top: 1px;
        left: 5px;
        width: 6px;
        height: 10px;
        border: 2px solid var(--color-bg);
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg);
      }
    }
  }

  &__button {
    border: 0;
    outline: 0;
    border-radius: var(--border-radius);
    background: var(--color-action);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    margin-top: 1.5rem;
    position: relative;
    cursor: pointer;

    span {
      color: var(--color-bg);
      font-size: var(--font-size-xl);
      font-family: var(--font-family);
      font-weight: 700;
      padding: 0 0.75rem 0 0;
      transition: var(--transition);
    }

    &::after {
      content: '';
      display: block;
      width: 0.875rem;
      height: 0.875rem;
      border: 4px solid var(--color-bg);
      border-width: 4px 4px 0 0;
      transform: rotate(45deg);
      transform-origin: 0 0;
      position: absolute;
      top: calc(50% - 0.5rem);
      right: 1.5rem;
      transition: var(--transition);
    }

    &:hover {
      span {
        padding-right: 1.5rem;
      }
      &::after {
        right: 1.25rem;
      }
    }

    &.is--busy {
      opacity: 0.5;
      cursor: wait;

      span {
        padding: 0 0.75rem 0 0 !important;
      }

      &::after {
        display: none;
      }
    }
  }
}
</style>
