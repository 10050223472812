const webinar = {
  title:
    'Oglądaj - Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe',
  headline:
    '&ldquo;Skuteczne Zarządzanie - Jak zmierzyć i usprawnić procesy usługowe&rdquo;',
  lead: `Na godzinę przed rozpoczęciem otrzymasz na swoją skrzynkę mailową link do sesji masterclass.`,
  video: '706477547?h=23a1a6c662',
  poster: 'video/poster-video-trailer.jpg',
  section_transcription: {
    title: 'Transkrypcja webinaru',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas faucibus ornare nibh, vel dignissim metus placerat in. Donec at purus at eros bibendum interdum. Quisque finibus bibendum velit, vitae blandit mauris mollis vitae.',
  },
  section_knowledge: {
    title: 'Już poznałeś:',
    list: [
      `Kim jest Skuteczny Lider AD2022`,
      `Dlaczego empatia i przywództwo to paradoksalne połączenie`,
      `Jak zdefiniować portfolio procesów w organizacji`,
      `W jaki sposób określić poziom efektywności procesów`,
      `Jak stworzyć środowisko pracy, w którym ludzie sami chcą mierzyć procesy`,
      `Jak wyeliminować marnotrawstwo`,
      `Jak dzięki eliminacji marnotrawstwa generować oszczędności w firmie`,
    ],
  },
  section_challenges: {
    title: 'Znasz też najczęstsze wyzwania branży usługowej:',
    list: [
      `Brak wykwalifikowanych ludzi na rynku pracy`,
      `Duża rotacja pracowników`,
      `Niekontrolowany wzrost wynagrodzeń`,
      `Praca zdalna<br/> - rozproszone zespoły`,
      `Podział na silosy w organizacji`,
      `Brak standardów zarządzania`,
      `Rosnące koszty i pogłębiająca się inflacja`,
      `Niedopasowane struktury organizacyjne`,
    ],
    icons: [
      'work.png',
      'social-care.png',
      'profits.png',
      'work-from-home.png',
      'silo.png',
      'checklist.png',
      'crisis.png',
      'cost.png',
    ],
  },
  section_form: {
    title: 'Chcę skorzystać z Bezpłatnej Konsultacji',
    subtitle:
      'Skorzystaj z naszego doświadczenia popartego świetnymi wynikami naszych klientów. Zadaj nam dowolne pytanie na temat wyzwań biznesowych przed którymi stanęła Twoja firma.',
    text: `Bezpłatną konsultację możesz wykorzystać na skonsultowanie dowolnego tematu
    związanego z wyzwaniami, przed którymi stoisz Ty i Twoja firma.<br/>
    Zawsze umawiamy dogodny dla obu stron termin i dbamy o najwyższy poziom udzielanych odpowiedzi.`,
    process: [
      'Wypełnij formularz (lub kliknij w button Chcę skorzystać z Bezpłatnej Konsultacji)',
      'Skontaktujemy się z Tobą i umówimy spotkanie, na którym zbadamy potrzeby Twojej organizacji i dowiesz się więcej o naszych doświadczeniach w Twojej branży.',
      'Spotkamy się ponownie, aby przedstawić Ci proponowane przez nas rozwiązania.',
      'Następnie wspólnie dopasujemy rozwiązania i podejmiesz decyzję o rozpoczęciu projektu.',
      'Uzgodnimy wspólnie plan prac – harmonogram projektu.',
      'Rozpoczniemy pracę według ustaleń.',
    ],
    steps_title: 'Zobacz, jak przebiegnie proces:',
    steps: [
      `Wypełnij krótki formularz Chcę skorzystać z Bezpłatnej Konsultacji`,
      `Skontaktujemy się z Tobą i umówimy spotkanie, na którym zbadamy potrzeby Twojej organizacji i dowiesz się więcej o naszych doświadczeniach w Twojej branży.`,
      `Spotkamy się ponownie, aby przedstawić Ci proponowane przez nas rozwiązania.`,
      `Następnie wspólnie dopasujemy rozwiązania i podejmiesz decyzję o rozpoczęciu projektu.`,
      `Uzgodnimy wspólnie plan prac – harmonogram projektu.`,
      `Rozpoczniemy pracę według ustaleń.`,
    ],
  },
  section_why: {
    title: 'Dlaczego warto?',
    text: `Pierwsza rozmowa nic Cię nie kosztuje, a może rozwiązać Twój problem
    i/lub przybliżyć Cię do celów biznesowych. Skorzystaj z naszego
    doświadczenia popartego świetnymi wynikami naszych klientów. Być może
    będziemy mogli pomóc Ci zrealizować nie tylko cele biznesowe, ale
    również zaangażować ludzi.`,
    image: 'section-why-intro.jpg',
  },

  leanpassion: [
    {
      value: 16,
      description: 'Lat doświadczenia',
    },
    {
      value: '500+',
      description: 'Klientów na świecie',
    },
    {
      value: '750 <small>mln PLN</small>',
      description: 'Oszczędności klientów',
    },
    {
      value: '400+',
      description: 'Transformacji',
    },
    {
      value: 4,
      description: 'Własne technologie',
    },
    {
      value: '6-8 <small>miesięcy</small>',
      description: 'Średni czas zwrotu inwestycji',
    },
  ],

  clients_list: [
    'adamed',
    'amway',
    'bnp_paribas',
    'carlsberg',
    'credit_agricole',
    'fresh_logistics',
    'iglotex',
    'indykpol',
    'infosys',
    'kghm',
    'krosno',
    'lufthansa',
    'mbank',
    'mleasing',
    'pkp_intercity',
    'pzu',
    'raben',
    'raiffeisen',
    'rtv_euro',
    'samsung',
    'stock',
    'synthos',
    'vw',
    'warta',
  ],

  errorPage: {
    headline:
      'Wielka szkoda, ale <em>wygasł Twój dostęp</em> do Sesji Masterclass',
    lead: `<strong>Nic nie szkodzi!</strong>  Na Twojego maila wyślemy
    nowy link z dostępem do sesji.<br/>
    Pamiętaj jednak, żeby wyrobić się z obejrzeniem w 24 godziny.`,
  },

  errorUnauthorized: {
    headline:
      'Przykro nam, ale ta Sesja Masterclass <em>nie jest dostepna</em>',
    lead: `Jeśli chcesz zobaczyć materiał video, prosimy o ponowne wypełnienie formularza zgłoszenia.`,
  },

  errorWait: {
    headline:
      'Przykro nam, ale ta Sesja Masterclass <em>nie jest dostepna</em>',
    lead: `Jeśli chcesz zobaczyć materiał video, prosimy o ponowne wypełnienie formularza zgłoszenia.`,
  },

  consultationTy: {
    headline: `Dziękujemy za poświęcony czas!`,
  },
}

export default webinar
