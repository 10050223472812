<template>
  <section class="site__section section--icons">
    <ul class="section__icons">
      <li
        v-for="icon of icons"
        :key="`icons-${icon.icon}`"
        class="section__icons-item"
        :style="backgroundIcon(icon.icon)"
      >
        <img
          :src="require(`@/assets/icons/${icon.icon}`)"
          :alt="icon.title"
          class="section__icons-icon"
        />
        <span v-html="icon.title" class="section__icons-title" />
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'SectionIcons',
  props: {
    icons: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    backgroundIcon(icon) {
      const file = require(`@/assets/icons/${icon}`)
      return `background-image: url(${file})`
    },
  },
}
</script>

<style lang="scss">
.section {
  &--icons {
    margin: 2rem 1rem;
    background: var(--color-main);
    border-radius: var(--border-radius);
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;

    @media screen and (max-width: 768px) {
      width: 90%;
      padding: 1rem 0rem 0;
      justify-content: center;
      align-items: flex-start;
      column-gap: 1rem;
    }

    &-item {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 2rem 1rem;
      position: relative;
      background-size: 0;
      max-width: 30%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 0;
        max-width: 33%;
      }

      &::before {
        content: '';
        position: absolute;
        top: -15%;
        left: -10%;
        width: 100%;
        height: 130%;
        background-image: inherit;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 0 0;
        opacity: 0.1;
        z-index: 1;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }

    &-icon {
      display: block;
      max-height: 5rem;
      max-width: 5rem;

      @media screen and (max-width: 768px) {
        max-height: 4rem;
        max-width: unset;
      }
    }

    &-title {
      color: var(--color-bg);
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 1.2;
      padding-left: 1.5rem;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 768px) {
        padding: 1rem 0 0;
        font-size: var(--font-size-lg);
        text-align: center;
      }
    }
  }
}
</style>
