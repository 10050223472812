<template>
  <div class="site__container site--player">
    <MainHeader />

    <div v-if="isLoading === true" class="section__video-loader">
      <VideoLoader :progress="isLoading" />
    </div>

    <template
      v-else-if="
        videoAccess === 401 ||
        (videoAccess === true && accessData.hasAccess === false)
      "
    >
      <SectionHeadline
        :headline="webinar.errorPage.headline"
        :lead="webinar.errorPage.lead"
      />
      <div class="section__buttons">
        <button
          type="button"
          class="form__button"
          :class="{
            'is--busy': prolongAction === 'busy',
            'is--ok': prolongAction === true,
          }"
          @click="prolongExpiration()"
        >
          <span v-if="!prolongAction">Chcę odzyskać dostęp</span>
          <span v-else-if="prolongAction === 'busy'">Wysyłamy dostęp...</span>
          <span v-else-if="prolongAction === true">Nowy dostęp przyznany</span>
        </button>
      </div>
    </template>

    <template v-if="videoAccess === 404">
      <SectionHeadline
        :headline="webinar.errorUnauthorized.headline"
        :lead="webinar.errorUnauthorized.lead"
      />
    </template>

    <template v-if="videoAccess === true">
      <SectionHeadline
        v-if="accessData.hasAccess === true"
        :headline="webinar.headline"
        :lead="null"
      />
      <section class="site__section section--player">
        <div
          v-if="isLoading === false && accessData.hasAccess === true"
          class="section__video"
        >
          <VideoPlayer :video="webinar.video" source="vimeo" />
        </div>
      </section>
    </template>

    <template v-if="videoAccess === true && accessData.hasAccess === true">
      <section class="site__section section--double">
        <div class="section__knowledge">
          <h3 class="section__title">{{ webinar.section_knowledge.title }}</h3>
          <ol class="section__knowledge-list">
            <li
              v-for="(item, i) of webinar.section_knowledge.list"
              :key="`knowledge-${i}`"
              class="section__knowledge-list-item"
              v-html="item"
            />
          </ol>
        </div>

        <div class="section__challenges">
          <h3 class="section__title">
            Znasz też najczęstsze wyzwania branży usługowej:
          </h3>
          <ol class="section__challenges-list">
            <li
              v-for="(item, i) of webinar.section_challenges.list"
              :key="`challenges-${i}`"
              class="section__challenges-list-item"
            >
              <div class="section__challenges-list-item-icon">
                <img
                  :src="
                    require(`@/assets/icons/${webinar.section_challenges.icons[i]}`)
                  "
                  :alt="item"
                />
              </div>
              <div class="section__challenges-list-item-label" v-html="item" />
            </li>
          </ol>
        </div>
      </section>
    </template>

    <section
      class="site__section section--form"
      :class="{ 'is--thankyou': thankyou }"
    >
      <div v-if="!thankyou" class="section__form-container">
        <h3 class="section__form-title">
          {{ webinar.section_form.title }}
        </h3>
        <div class="section__form-intro">
          <p v-html="webinar.section_form.subtitle" />
          <p v-html="webinar.section_form.text" />
        </div>
      </div>
      <FormConsultation v-if="!thankyou" />

      <div v-if="thankyou" class="section__form-container">
        <h3 class="section__form-title">
          {{ webinar.consultationTy.headline }}
        </h3>
      </div>
    </section>

    <section class="site__section section--steps">
      <h4 class="section__steps-title">
        {{ webinar.section_form.steps_title }}
      </h4>
      <ol class="section__steps-list">
        <li
          v-for="(step, i) of webinar.section_form.steps"
          :key="`step-${i}`"
          class="section__steps-list-item"
        >
          <span>{{ step }}</span>
        </li>
      </ol>
    </section>

    <section class="site__section section--clients">
      <h3 class="section__title">
        Z naszego doświadczenia skorzystali między innymi:
      </h3>
      <ul class="section__clients">
        <li v-for="(client, i) of webinar.clients_list" :key="`client-${i}`">
          <img :src="`logos/${client}.jpg`" alt="" />
        </li>
      </ul>
    </section>

    <section class="site__section section--why">
      <div class="section__why-container">
        <h3 class="section__title">
          {{ webinar.section_why.title }}
        </h3>
        <div class="section__why-text" v-html="webinar.section_why.text" />
      </div>
      <div class="section__why-image">
        <img
          :src="`photos/${webinar.section_why.image}`"
          :alt="webinar.section_why.title"
        />
      </div>
      <ul class="section__why-effects">
        <li v-for="(item, i) of webinar.leanpassion" :key="`effect-${i}`">
          <div class="section__why-effects-value" v-html="item.value" />
          <div class="section__why-effects-description">
            {{ item.description }}
          </div>
        </li>
      </ul>
      <button
        v-if="!thankyou"
        type="button"
        class="form__button"
        @click="goToForm()"
      >
        <span>Skorzystaj z bezpłatnej konsultacji</span>
      </button>
    </section>

    <section class="site__section section--info">
      <h3 class="section__title">Potrzebujesz wsparcia lub pomocy?</h3>
      <div class="section__text">
        Napisz na
        <a href="mailto:pomoc@leanpassion.pl">pomoc@leanpassion.pl</a> lub
        zadzwoń <strong>+48 22 435 79 40</strong>
      </div>
    </section>
    <MainFooter />
  </div>
</template>

<script>
import webinar from '@/models/landing-player'

import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import SectionHeadline from '@/components/SectionHeadline'
import VideoPlayer from '@/components/VideoPlayer'
import VideoLoader from '@/components/VideoLoader'
// import ModuleTranscription from '@/components/ModuleTranscription.vue'
import FormConsultation from '@/components/FormConsultation'

import { mapState } from 'vuex'
import { actionNames } from '@/store/application'

export default {
  name: 'ThankyouView',

  data() {
    return {
      webinar,
      status: null,
    }
  },

  computed: {
    ...mapState({
      videoAccess: (state) => state.application.videoAccess,
      accessData: (state) => state.application.accessData,
      isLoading: (state) => state.application.isLoading,
      prolongAction: (state) => state.application.prolongAction,
      thankyou: (state) => state.consultation.thankyou,
    }),
  },

  methods: {
    async getVideoData() {
      const params = this.$route.params
      if (!params.id || params.id === 'consultation') return

      const response = await this.$store.dispatch(
        `application/${actionNames.ACCESS_CHECK}`,
        { user_id: params.id }
      )
      if (response) {
        this.getUserData()
      }
    },

    async getUserData() {
      const params = this.$route.params
      if (!params.id) return

      const response = await this.$store.dispatch(
        `application/${actionNames.GET_USER_DATA}`,
        params.id
      )
      if (response) {
        console.log(response)
      }
    },

    goToForm() {
      const form = document.getElementById('form-consultation')
      form.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },

    async prolongExpiration() {
      const params = this.$route.params
      if (!params.id) return
      if (this.prolongAction) return

      const response = await this.$store.dispatch(
        `application/${actionNames.PROLONG_EXPIRATION}`,
        { user_id: params.id }
      )
      if (response) {
        console.log(response)
      }
    },
  },

  mounted() {
    this.getVideoData()
  },

  components: {
    MainHeader,
    MainFooter,
    SectionHeadline,
    VideoPlayer,
    VideoLoader,
    // ModuleTranscription,
    FormConsultation,
  },
}
</script>
<style lang="scss">
@use '@/style/_lists.scss';

.site--player {
  .section {
    &--welcome {
      @media screen and (max-width: 1024px) and (max-height: 500px) {
        padding: 0.5rem 1rem 1rem;
      }

      @media screen and (max-width: 1024px) {
        text-align: center;
      }
    }

    &__headline {
      margin: 0.5rem 0;
      max-width: 100%;
      font-size: var(--font-size-title-xl);

      @media screen and (max-width: 1024px) {
        font-size: var(--font-size-title);
      }
    }

    &--player {
      margin: auto;
      justify-content: center;
    }

    &__video {
      margin: auto 1rem;
      max-height: 100%;
      align-items: center;

      .video {
        &__container {
          max-height: 670px;
          display: flex;
          justify-content: center;
          overflow: visible;
        }
        &__player {
          // position: relative;
          // height: unset;
          // width: unset;
          max-width: 100%;
          // max-height: calc(100vh - 17rem);
          box-shadow: var(--box-shadow-deep);
          border-radius: var(--border-radius-xl);
          background: var(--color-text);

          @media screen and (max-width: 1024px) {
            // max-width: 90%;
          }

          @media screen and (max-width: 1024px) and (max-height: 500px) {
            max-height: 90vh;
          }
        }
      }

      &-loader {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    &--transcription,
    &--calendar,
    &--info {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      line-height: 1.75;

      .form__button {
        margin-top: 2rem;
        max-width: 17rem;
        background-color: var(--color-main);

        span {
          margin-right: 0.5rem;
        }
      }

      a {
        color: var(--color-main);
        font-weight: 700;
        transition: var(--transition);

        &:hover {
          color: var(--color-action);
        }
      }
    }

    &__title {
      flex: 100%;
      margin: 2rem 0 1rem;
      text-align: center;
      font-size: var(--font-size-title-xl);
      font-weight: 700;
      line-height: 1.2;
    }

    &__text {
      padding: 0 2rem 1rem;
      text-align: center;
      font-size: var(--font-size-lg);
    }

    &--double {
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem;
      margin-top: 2rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__knowledge {
      max-width: 40%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }

      .section__title {
        flex: 0;
        font-size: var(--font-size-title);
        margin-bottom: 2rem;
      }

      &-list {
        @include lists.list-with-check-mark;
        font-size: var(--font-size-lg);
        padding: 0 1rem;
        margin: 0;
        flex: 100%;
      }
    }

    &__challenges {
      max-width: 55%;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        margin-top: 3rem;
      }

      .section__title {
        font-size: var(--font-size-title);
        margin-bottom: 2rem;
      }

      &-list {
        @include lists.list-with-box-icon;
        font-size: var(--font-size-lg);
        justify-content: space-between;
        padding: 2rem 3rem;
        column-gap: 1rem;
        row-gap: 1rem;

        @media screen and (max-width: 768px) {
          padding: 1rem;
          row-gap: 0;
        }

        li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: stretch;
          padding: 1rem;
          flex: 48%;

          @media screen and (max-width: 768px) {
            padding: 2rem;
            flex: 100%;
            max-width: 100%;
            flex-direction: row;
            align-items: center;
          }
        }

        &-item {
          &-icon {
            flex: 1 100%;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 3.5rem;
            max-height: 3.5rem;

            @media screen and (max-width: 768px) {
              max-width: 5rem;
              max-height: 5rem;
            }

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: contain;
              opacity: 0.4;
            }
          }
          &-label {
            flex: 100%;
            width: 100%;
            padding-top: 1rem;

            @media screen and (max-width: 768px) {
              padding: 0 0 0 2.5rem;
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    &--form {
      padding: 2rem;
      background: var(--color-main);
      border-radius: var(--border-radius-xl);
      box-shadow: var(--box-shadow-deep);
      margin: 2rem auto;
      max-width: 60rem;
      position: relative;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 2rem 1rem;
      }

      form {
        scroll-margin-top: 4rem;
      }

      &.is--thankyou {
        .section__form {
          &-container {
            max-width: 100%;
            width: 100%;
            text-align: center;
            justify-content: center;
            flex-direction: row;
            padding: 0.5rem 1rem;
          }
          &-title {
            width: 100%;
            padding: 0rem;
          }
        }
      }
    }

    &__form {
      &-container {
        padding: 1rem 3rem 1rem 1rem;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
          max-width: 100%;
          padding: 0rem 0rem 3rem;
        }
      }

      &-title {
        color: var(--color-bg);
        font-size: var(--font-size-title-xl);
        margin: 0;
        padding: 0.5rem 0 1rem;
        line-height: 1.3;
      }

      &-intro {
        color: var(--color-bg);
        flex: 100%;

        p {
          font-size: var(--font-size-lg);
          font-weight: 500;
          margin: 1rem 0 0;
          line-height: 1.7;

          &:last-child {
            font-weight: 400;
            font-size: var(--font-size);
            margin-top: 1.5rem;
          }
        }
      }
    }

    &--steps {
      padding: 3rem 1rem;
      max-width: 80%;
      margin: 0 auto;

      @media screen and (max-width: 768px) {
        padding: 3rem;
        max-width: 100%;
        flex-direction: column;
      }
    }

    &__steps {
      &-title {
        margin: 0;
        font-size: var(--font-size-title-xl);
        flex: 1;
        max-width: 13em;
        padding-right: 3rem;
        line-height: 1.4;

        @media screen and (max-width: 768px) {
          padding: 0 0 2rem;
          max-width: 100%;
        }
      }

      &-list {
        flex: 1;
        list-style: none;
        margin: 0;
        padding: 0 2rem 0 0;
        counter-reset: steps-items;

        @media screen and (max-width: 768px) {
          padding: 0 2rem;
        }

        li {
          display: block;
          padding-left: 2rem;
          padding-bottom: 1.5rem;
          line-height: 1.6;
          position: relative;
          counter-increment: steps-items;

          span {
            font-weight: 500;
            font-size: var(--font-size-lg);
          }

          &::before {
            content: counter(steps-items) '.';
            position: absolute;
            top: 2px;
            left: 0;
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--color-main);
            line-height: 1;
          }
        }
      }
    }

    &--clients {
      flex-direction: column;
    }
    &__clients {
      list-style: none;
      margin: 3rem 0;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      column-gap: 1rem;
      row-gap: 3rem;

      @media screen and (max-width: 768px) {
        margin: 2rem;
      }

      li {
        flex: 1 auto;
        min-width: 15%;
        max-width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
          min-width: 30%;
          max-width: 30%;
        }

        img {
          display: block;
          transition: var(--transition);
          filter: grayscale(1);
          opacity: 0.6;

          &:hover {
            opacity: 1;
            filter: grayscale(0);
          }
        }
      }
    }

    &--why {
      margin: 2rem 2rem;
      border-radius: var(--border-radius-xl);
      background: var(--color-bg-alt);
      padding: 3rem;
      border: var(--box-border-light);
      box-shadow: var(--box-shadow-light);
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        padding: 2.5rem;
        flex-direction: column;
      }

      .section__title {
        margin: 0;
        flex: 0;
      }

      .form__button {
        background-color: var(--color-main);
        margin: 3rem auto 1rem;
        max-width: 28rem;

        span {
          @media screen and (max-width: 768px) {
            padding-right: 1rem;
            line-height: 1.3;
          }
        }

        @media screen and (max-width: 768px) {
          max-width: 21rem;
        }
      }
    }
    &__why {
      &-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 50%;
        padding: 0rem 5rem 0 1rem;

        @media screen and (max-width: 768px) {
          max-width: 100%;
          padding: 0rem 0 3rem;
        }
      }
      &-text {
        flex: 0;
        margin: 0;
        font-size: var(--font-size-lg);
        padding: 2rem 0 0;
        line-height: 1.7;
      }
      &-image {
        flex: auto;
        max-width: 50%;
        overflow: hidden;
        border-radius: var(--border-radius);

        @media screen and (max-width: 768px) {
          max-width: 100%;
          margin: 0;
          border-radius: var(--border-radius-sm);
        }
      }

      &-effects {
        list-style: none;
        margin: 4rem auto 0;
        padding: 3rem 2rem 1rem;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 2rem;
        flex: 100%;
        max-width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.05);

        @media screen and (max-width: 768px) {
          padding: 0;
          margin-top: 3rem;
          padding-top: 2rem;
        }

        li {
          flex: 1 0 33%;
          max-width: 33%;
          line-height: 1.7;

          @media screen and (max-width: 768px) {
            flex-basis: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
          }
        }

        &-value {
          font-size: var(--font-size-title-xxl);
          font-weight: 800;
          color: var(--color-main);
          line-height: 1.1;

          @media screen and (max-width: 768px) {
            font-size: var(--font-size-title-xl);
            min-width: 40%;
            max-width: 40%;
            text-align: left;
            padding-right: 2rem;
          }

          small {
            font-size: 70%;
            @media screen and (max-width: 768px) {
              display: block;
              font-size: 60%;
            }
          }
        }
        &-description {
          font-size: var(--font-size-lg);

          @media screen and (max-width: 768px) {
            line-height: 1.4;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;

      .form__button {
        max-width: 20rem;
        margin: 0 auto 3rem;

        span {
          padding-right: 1rem;
        }

        &:hover {
          span {
            padding-right: 1.35rem;
          }
        }

        &.is--ok {
          background-color: var(--color-success);
          cursor: default;

          span {
            padding-right: 1.5rem !important;
          }

          &::after {
            transform: rotate(135deg) translate(-0.8rem, -1.2rem);
            width: 1.125rem;
            right: 1.5rem !important;
          }
        }
      }
    }
  }
}
</style>
