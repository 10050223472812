import { WEBINAR_ID, GET, POST } from '@/helpers/http'

export const types = {
  ACCESS_CHECK_SUCCESS: 'accessCheckSucces',
  ACCESS_CHECK_ERROR: 'accessCheckError',
  GET_USER_DATA_COMMIT: 'getUserDataCommit',
  SET_LOADING_STATUS: 'setLoadingStatus',
  PROLONG_EXPIRATION_COMMIT: 'prolongExpirationCommit',
}

export const actionNames = {
  ACCESS_CHECK: 'accessCheck',
  GET_USER_DATA: 'getUserData',
  PROLONG_EXPIRATION: 'prolongExpiration',
}

export default {
  namespaced: true,
  state: {
    videoAccess: false,
    accessData: {
      hasAccess: false,
    },
    userData: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      user_id: null,
    },
    isLoading: false,
    prolongAction: false,
  },

  mutations: {
    [types.SET_LOADING_STATUS](state, status) {
      state.isLoading = status
    },
    [types.ACCESS_CHECK_SUCCESS](state, { status, data }) {
      state.videoAccess = status
      state.accessData = { ...data }
    },
    [types.ACCESS_CHECK_ERROR](state, status) {
      state.isLoading = false
      state.videoAccess = status
      state.accessData = null
    },
    [types.GET_USER_DATA_COMMIT](state, data) {
      state.userData = { ...data }
    },
    [types.PROLONG_EXPIRATION_COMMIT](state, status) {
      state.prolongAction = status
    },
  },

  actions: {
    async [actionNames.ACCESS_CHECK]({ commit }, data) {
      commit(types.SET_LOADING_STATUS, true)
      const params = {
        user_id: data.user_id,
        webinar_id: WEBINAR_ID,
      }
      const query = Object.keys(params)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
        .join('&')
      const request = await GET(`/access-check?${query}`)
      const status = request.status

      if (status === 200) {
        const result = await request.json()
        commit(types.ACCESS_CHECK_SUCCESS, {
          status: true,
          data: result.accessData || {},
        })
        setTimeout(() => commit(types.SET_LOADING_STATUS, false), 100)
        return true
      } else {
        commit(types.ACCESS_CHECK_ERROR, status)
        return false
      }
    },

    async [actionNames.GET_USER_DATA]({ commit }, user_id) {
      const request = await GET(`/user-get?user_id=${user_id}`)
      const result = await request.json()
      commit(types.GET_USER_DATA_COMMIT, {
        user_id,
        ...result,
      })
      return true
    },

    async [actionNames.PROLONG_EXPIRATION]({ commit }, data) {
      commit(types.PROLONG_EXPIRATION_COMMIT, 'busy')
      const params = {
        path: '/user-prolongExpiration',
        user_id: data.user_id,
        webinar_id: WEBINAR_ID,
      }
      const request = await POST(params)
      const status = request.status

      if (status === 200) {
        commit(types.PROLONG_EXPIRATION_COMMIT, true)
        return true
      } else {
        commit(types.PROLONG_EXPIRATION_COMMIT, false)
        return false
      }
    },
  },
}
