import { createRouter, createWebHistory } from 'vue-router'
import MainView from '@/views/MainView'
import ContentView from '@/views/ContentView'
import ThankyouView from '@/views/ThankyouView'
import PlayerView from '@/views/PlayerView'

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
  },
  {
    path: '/thankyou',
    name: 'ThankyouView',
    component: ThankyouView,
  },
  {
    path: '/thankyou/:id',
    name: 'ThankyouUserView',
    component: ThankyouView,
  },
  {
    path: '/konsultacja',
    name: 'PlayerView',
    component: PlayerView,
  },
  {
    path: '/warunki-korzystania',
    name: 'WarunkiKorzystania',
    component: ContentView,
  },
  {
    path: '/polityka-prywatnosci',
    name: 'PolitykaPrywatnosci',
    component: ContentView,
  },
  {
    path: '/:id',
    name: 'PlayerUserView',
    component: PlayerView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
