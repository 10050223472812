<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 28">
    <path
      d="M32,30H0V2H32ZM5.333,28.667V23.333h-4v5.333Zm20,0V3.333H6.667V28.667Zm5.333,0V23.333h-4v5.333ZM22.667,16l-12,6.667V9.333Zm4,.667V22h4V16.667ZM5.333,22V16.667h-4V22ZM12,20.4,19.921,16,12,11.6ZM26.667,10v5.333h4V10ZM5.333,15.333V10h-4v5.333Zm21.333-12V8.667h4V3.333ZM5.333,8.667V3.333h-4V8.667Z"
      transform="translate(0 -2)"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconVideo',
}
</script>
