<template>
  <div class="site__wrapper">
    <router-view />
  </div>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  name: 'LeanpassionWebinar',

  watch: {
    $route(to, from) {
      if (to !== from) {
        document.getElementById('leanpassion-webinar').scrollTo(0, 0)
      }
    },
  },
}
</script>

<style lang="scss">
@import url('style/fonts.css');
@import url('style/_main.scss');

html {
  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
}

.site {
  &__wrapper {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 0;
  }

  &__section {
    display: flex;
  }
}
</style>
