<template>
  <div class="content__icon">
    <div class="content__icon-img">
      <div v-html="iconFile" />
    </div>
    <div class="content__icon-title" v-html="title" />
    <!-- div class="content__icon-desc">{{ desc }}</div-->
  </div>
</template>

<script>
export default {
  name: 'ContentIcon',

  data() {
    return {
      iconFile: null,
    }
  },

  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },

  methods: {
    fileContent: async function (filename) {
      const path = require(`@/assets/icons/${filename}.svg`)
      const file = await fetch(path)
      const ret = await file.text()
      return ret.replace(/[\n\r\t]/g, '')
    },
  },

  async mounted() {
    this.iconFile = await this.fileContent(this.icon)
  },
}
</script>
