import { POST } from '@/helpers/http'

export const types = {
  SEND_COMMIT: 'sendCommit',
  SEND_ERROR: 'sendError',
}

export const actionNames = {
  SEND: 'send',
}

export default {
  namespaced: true,

  state: {
    data: {
      name: null,
      email: null,
      phone: null,
      date: null,
      time: null,
    },

    fields: {
      name: {
        type: 'text',
        label: 'Imię i nazwisko',
        pattern: '[^0-9]{3,120}',
        reg: '[^0-9]*',
        minLength: 3,
        maxLength: 120,
      },
      email: {
        type: 'mail',
        label: 'E-mail',
        pattern: '[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}',
        minLength: 3,
        maxLength: 120,
      },
      phone: {
        type: 'tel',
        label: 'Telefon',
        pattern: '[0-9]{9}',
        reg: '[0-9]',
        minLength: 9,
        maxLength: 9,
      },
    },
    progress: false,
    thankyou: false,
    error: false,
  },

  mutations: {
    [types.SEND_COMMIT](state) {
      state.thankyou = true
      state.progress = false
    },

    [types.SEND_ERROR](state, status) {
      state.error = status
      state.progress = false
    },
  },

  actions: {
    async [actionNames.SEND]({ commit, rootState, state }, data) {
      state.progress = true

      const request = {
        path: '/form-submission',
        ...data,
      }
      const response = await POST(request)
      if (response.status === 400) {
        commit(types.SEND_ERROR, 400)
      } else if (response.status === 404) {
        commit(types.SEND_ERROR, 404)
      } else if (response.status === 200) {
        const result = await response.json()
        rootState.application.userData.user_id = result.userId
        commit(types.SEND_COMMIT)
      }
      return response.status
    },
  },
}
