<template>
  <div class="site__container site--thankyou">
    <MainHeader />
    <SectionHeadline :headline="webinar.headline" :lead="webinar.lead" />
    <!--section class="site__section section--player">
      <div class="section__video">
        <VideoPlayer :video="webinar.video" :poster="webinar.poster" />
      </div>
    </section-->
    <section class="site__section section--info">
      <h3 class="section__title">{{ webinar.section_info.title }}</h3>
      <div class="section__text">{{ webinar.section_info.text }}</div>
      <button type="button" class="form__button" @click="goToBlog()">
        <span>Przejdź na bloga</span>
      </button>
    </section>
    <section class="site__section section--calendar">
      <h3 class="section__title">{{ webinar.section_calendar.title }}</h3>
      <div class="section__calendar">
        <div class="section__calendar-icon">
          <img
            :src="require('@/assets/icons/icon-calendar.jpg')"
            alt="Dodaj do kalendarza"
          />
        </div>
        <div class="section__calendar-description">
          <div v-html="webinar.section_calendar.text" />
          <button
            type="button"
            class="form__button"
            :class="{ 'is--busy': calendarBusy }"
            @click="downloadCalendar()"
          >
            <span v-if="!calendarBusy">Dodaj do kalendarza</span>
            <span v-if="calendarBusy">Pobieranie kalendarza...</span>
          </button>
        </div>
      </div>
    </section>
    <section class="site__section section--info">
      <h3 class="section__title">Potrzebujesz wsparcia lub pomocy?</h3>
      <div class="section__text">
        Napisz na
        <a href="mailto:pomoc@leanpassion.pl">pomoc@leanpassion.pl</a> lub
        zadzwoń <strong>+48 22 435 79 40</strong>
      </div>
    </section>
    <MainFooter />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { CALENDAR_API } from '@/helpers/http.js'
import webinar from '@/models/landing-thankyou'

import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import SectionHeadline from '@/components/SectionHeadline'
// import VideoPlayer from '@/components/VideoPlayer'

export default {
  name: 'ThankyouView',

  data() {
    return {
      webinar,
      calendarBusy: false,
    }
  },

  computed: {
    ...mapState({
      userData: (state) => state.application.userData,
    }),
  },

  methods: {
    goToBlog() {
      const blogUrl = '//leanpassion.pl/blog'
      window.open(blogUrl, 'Leanpassion Blog')
    },

    async downloadCalendar() {
      if (!this.userData) return
      this.calendarBusy = true
      const path = `${CALENDAR_API}?user_id=${this.userData.user_id}`
      const request = await fetch(path)

      if (request.status === 200) {
        const response = await request.blob()
        const url = window.URL.createObjectURL(response)
        let link = document.createElement('a')
        link.href = url
        link.download = 'calendar-masterclass.ics'
        document.body.appendChild(link)
        link.click()
        link.remove()
        this.calendarBusy = false
      } else {
        this.calendarBusy = false
      }
    },
  },

  components: {
    MainHeader,
    MainFooter,
    SectionHeadline,
    // VideoPlayer,
  },
}
</script>

<style lang="scss">
.site--thankyou {
  .plyr--video {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .video__container {
    box-shadow: var(--box-shadow-deep);
  }

  .section {
    &__headline {
      margin-bottom: 1rem;
    }

    &--player {
      max-width: 70%;
      margin: auto;
    }

    &__video {
      margin: auto;
    }

    &--info,
    &--calendar {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;

      .form__button {
        margin-top: 2rem;
        max-width: 17rem;
        background-color: var(--color-main);

        span {
          margin-right: 0.5rem;
        }
      }

      a {
        color: var(--color-main);
        font-weight: 700;
        transition: var(--transition);

        &:hover {
          color: var(--color-action);
        }
      }
    }

    &--info {
      .section__title {
        margin-top: 0;
      }
    }

    &__title {
      flex: 100%;
      margin: 2rem 0 1rem;
      text-align: center;
      font-size: var(--font-size-title-xl);
      font-weight: 700;
      line-height: 1.2;
    }

    &__text {
      padding: 0 2rem 1rem;
      text-align: center;
      font-size: var(--font-size-lg);
    }

    &__calendar {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1 100%;
      padding: 1.125rem;
      border: var(--box-border);
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
      margin-top: 1rem;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }

      &-icon {
        flex: 1;
        max-width: 20rem;
        border-radius: calc(var(--border-radius) * 0.5);
        overflow: hidden;

        @media screen and (max-width: 768px) {
          max-width: 100%;
        }
      }
      &-description {
        flex: 1;
        padding: 1rem 3rem;

        @media screen and (max-width: 768px) {
          padding: 2rem 0rem 1rem;
          align-items: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          text-align: center;
        }

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: var(--font-size-xl);

          @media screen and (max-width: 768px) {
            align-items: center;
          }
        }

        .form__button {
          margin-top: 2rem;
          max-width: 17rem;

          span {
            font-size: var(--font-size-lg);
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
