<template>
  <form
    class="form__container"
    :id="`form-${formName}`"
    @keypress="enterPressed"
  >
    <fieldset class="form__fields">
      <div
        v-for="(item, i) of fields"
        :key="`field-${i}`"
        class="form__fields-item"
      >
        <input
          :type="item.type"
          :name="`field-${i}`"
          v-model="form[i]"
          :pattern="item.pattern"
          :id="`${formName}-field-${i}`"
          @keypress="isValidInput($event, item.reg)"
          :placeholder="item.label"
          class="form__fields-input"
          :minlength="item.minLength"
          :maxlength="item.maxLength"
          required
        />
        <label :for="`${formName}-field-${i}`" class="form__fields-label">
          {{ item.label }}
        </label>
      </div>
      <div class="form__fields-item">
        <div class="form__fields-agrees">
          <label class="form__checkbox">
            <input type="checkbox" required="" value="gdpr-regulations" />
            <p>
              Zapoznałem się i zgadzam się z zapisami
              <router-link to="/polityka-prywatnosci/">
                polityki prywatności </router-link
              >, a swoje dane do kontaktu zostawiam świadomie i dobrowolnie. Mam
              świadomość, że mogę zażądać poprawiania swoich danych osobowych,
              ograniczenia ich przetwarzania lub usunięcia.*
            </p>
          </label>
        </div>
      </div>
      <button
        type="submit"
        class="form__button"
        :class="{ 'is--busy': busy }"
        @click="validateForm"
      >
        <span v-html="!busy ? 'Wyślij' : 'Trwa wysyłanie...'" />
      </button>
    </fieldset>
  </form>
</template>

<script>
import { WEBINAR_ID } from '@/helpers/http'
import { mapState } from 'vuex'
import { actionNames } from '@/store/consultation'

export default {
  name: 'FormConsultation',

  props: {
    formName: {
      type: String,
      default: 'consultation',
    },
  },

  data() {
    return {
      errors: false,
      form: {},
    }
  },

  computed: {
    ...mapState({
      formFields: (state) => state.consultation.data,
      fields: (state) => state.consultation.fields,
      busy: (state) => state.consultation.progress,
      userData: (state) => state.application.userData,
    }),
  },

  methods: {
    validateForm() {
      const form = document.getElementById(`form-${this.formName}`)
      const btn = form.querySelector('.form__button')
      const isValid = form.checkValidity()

      this.errors = !isValid ? true : false

      if (!isValid) {
        form.classList.add('show--errors')
        btn.setAttribute('type', 'submit')
        btn.click()
      } else {
        form.classList.remove('show--errors')
        btn.setAttribute('type', 'button')
        this.sendForm()
      }
    },

    sendForm() {
      const { name, ...data } = this.form
      const splitted = name.split(' ')
      const first_name = splitted[0]
      splitted.shift()
      const last_name = splitted.join(' ')

      const object = {
        first_name,
        last_name,
        ...data,
        user_id: this.userData.user_id,
        webinar_id: WEBINAR_ID,
      }
      console.log(object)
      this.$store.dispatch(`consultation/${actionNames.SEND}`, object)
    },

    isValidInput(e, reg) {
      const pattern = new RegExp(reg)
      if (!pattern.test(e.key)) return e.preventDefault()
    },

    enterPressed(event) {
      if (event.keyCode === 13) {
        this.validateForm()
      }
    },

    fillForm() {
      if (this.userData) {
        const user = this.userData
        this.form = {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          phone: user.phone,
        }
      }
    },
  },

  watch: {
    userData() {
      this.fillForm()
    },
  },

  mounted() {
    this.fillForm()
  },
}
</script>
