const content = {
  title: 'Polityka prywatności',
  text: `
<p>
Administratorem Twoich danych osobowych jest Leanpassion Sp. z o.o. z siedzibą przy ul. Komitetu Obrony Robotników 48, 02-146 Warszawa (dalej „Leanpassion”), właściciel stron internetowych poświęconych szeroko pojętej koncepcji „lean management”, takich jak: leanpassion.pl, strategialean.pl, sherlockwaste.com, i-lpe.pl, leanblackbelt.pl, konferencja.leanpassion.pl
</p><p>
Przetwarzanie Twoich danych osobowych pozwala nam na dostarczanie Ci najnowszych informacji dotyczących strategii LEAN np. poprzez wysyłane od czasu do czasu naszego newslettera.
</p><p>
Leanpassion Sp. z o.o szanuje prawa Użytkowników do prywatności i dba o ochronę danych osobowych, którymi administruje zgodnie z przepisami Rozporządzenia o Danych Osobowych (RODO). W tym celu do zbierania danych osobowych, przechowywania i każdego innego przetwarzania wykorzystuje odpowiednie narzędzia techniczne i informatyczne oraz stosuje specjalistyczne procedury organizacyjne.
</p>
<h4>Przedstawione poniżej zasady opisują, jakie dane osobowe gromadzimy i jak możemy z tych informacji korzystać.</h4>
<p>
Korzystając z naszej strony internetowej możesz zostać poproszony o podanie swoich danych osobowych takich jak: imię, nazwisko, adres e-mail, nr telefonu, stanowisko.
Powyższe dane osobowe przetwarzane są na podstawie zgody wyrażonej podczas rejestracji w serwisie, lub podczas wypełniania formularza kontaktowego /mailingowego. Brak Twojej zgody przetwarzanie danych osobowych uniemożliwi przesłanie formularza.
</p><p>
Podstawą prawną przetwarzania danych osobowych jest zgoda osoby, której dane dotyczą.
Będziemy przetwarzać Twoje dane osobowe do czasu wycofania zgody, co możesz uczynić w każdym momencie.
Przysługuje Ci prawo do kontroli przetwarzanych przez Leanpassion Twoich danych osobowych w tym prawo do wglądu w swoje dane, ich poprawianie, żądania uzupełnienia, uaktualnienia, sprostowania lub usunięcia.
</p><p>
Po przyjęciu wniosku o usunięcie Twoich danych osobowych z systemu nie będziemy już ich przetwarzać. Wyjątek stanowi sytuacja gdy istnieją: ważne prawnie uzasadnione podstawy do przetwarzania danych, które według prawa uznaje się za nadrzędne wobec sprzeciwu, lub podstawy do ustalenia, dochodzenia lub obrony roszczeń.<br/>
</p>
<h4>Bezpieczeństwo</h4>
<p>
System informatyczny oraz dane, które zbieramy i przetwarzamy, są zabezpieczone przed wglądem osób trzecich, spełniający bardzo wysokie wymagania bezpieczeństwa zarówno pod względem technicznym (informatycznym), jak i prawnym.<br/>
Współpracujemy z ekspertami IT zapewniającymi stałe bezpieczeństwo danych osobowych i dostosowywanie systemu informatycznego do zmieniającej się dynamicznie rzeczywistości i zagrożeń. Programiści czuwają nad bieżącym wdrażaniem odpowiednich procedur i bezpieczeństwem Twoich danych.
</p></p>
W celu zapewnienia niezawodności i najwyższej jakości usługi korzystamy z zewnętrznych rozwiązań informatycznych, oferowanych przez najlepszych w Polsce dostawców, którzy zapewniają przestrzeganie obowiązujących przepisów prawa, w tym dotyczących ochrony danych osobowych. Podmioty z którymi współpracujemy mogą uzyskać dostęp do Twoich danych osobowych, jedynie w zakresie potrzebnym do realizacji oferowanych przez nie usług np. hosting serwerów pocztowych, wysyłka newslettera itp.
Nie przekazujemy danych osobowych, którymi administrujemy do państw trzecich.
Udostępnianie Twoich danych osobowych możliwe jest wyłącznie na zasadach i wypadkach przewidzianych przez obowiązujące przepisy prawa.
</p><p>
W przypadku naruszenia prawa, lub gdy będzie to wymagane przepisami prawa, możemy udostępnić posiadane dane upoważnionym organom i osobom, w tym organom wymiaru sprawiedliwości.
</p><p>
Nasze strony internetowe wykorzystują tzw. ciasteczka (cookies), przechowywane przez Twoją przeglądarkę internetową na twardym dysku Twojego komputera w celu przyspieszenia współpracy pomiędzy Twoim komputerem /telefonem, a serwerami. Dzięki wykorzystaniu cookies nie musisz ponownie wprowadzać informacji za każdym razem kiedy wracasz na naszą stronę. Wyłączenie w przeglądarce internetowej opcji zezwalającej na zapisywanie plików cookies, nie uniemożliwia całkowitego korzystania z serwisu, ale w niektórych sytuacjach dostęp do jego części może być wolniejszy.<br/>
</p><p>
Ze względów bezpieczeństwa dane o adresie IP użytkownika serwisu zapisywane są w logach systemowych serwera. Sam adres IP nie pozwala nam na bezpośrednią identyfikację użytkownika.
Do korzystania z usług świadczonych za pośrednictwem witryny internetowej serwisu wymagana jest poprawnie skonfigurowana przeglądarka internetowa.
</p><p>
Dbamy o prywatność Użytkowników serwisu, w związku z tym każdy korzystający z naszej aplikacji lub witryny internetowej zobowiązany jest do przestrzegania zakazu nadużywania środków komunikacji elektronicznej.<br/>
</p><p>
W razie wykrycia nieprawidłowości lub zagrożeń powodowanych przez osoby nierespektujące przepisów prawa i regulacji obowiązujących w naszym serwisie, będziemy współpracować z policją, prokuraturą, sądami, by pomóc w ujęciu osób dopuszczających się czynów zabronionych.<br/>
</p>

<h4>Pliki Cookies</h4>
<p>Podczas przeglądania Serwisu, stosownie do udzielonej zgody oraz konfiguracji przeglądarki, na Twoim urządzeniu (urządzeniu końcowym) może zostać zapisany przez nas lub firmę współpracującą (naszego podwykonawcę) jeden lub kilka plików cookie. Prosimy o zapoznanie się z poniższymi informacjami na temat plików cookie oraz sposobu ich wykorzystywania.
</p><p>
Cookies to małe pliki, które są umieszczone na dysku twardym komputera podczas odwiedzania niektórych stron internetowych. Zawierają one informacje na temat urządzenia i zazwyczaj nie zawierają żadnych danych osobowych. Pliki te nie mogą być wykorzystane do infekowania urządzenia wirusami lub innym złośliwym oprogramowaniem.
</p><p>
W plikach cookies przechowujemy podstawowe, anonimowe informacje o użytkownikach (np. identyfikator) oraz dane służące podniesieniu komfortu korzystania z Serwisu, potrzebne dla optymalizacji i poprawnego wyświetlania treści zawartych na stronach oraz kampanii reklamowych.
</p><p>
Jako użytkownik Serwisu możesz w dowolnej chwili zaprzestać dostarczania tych informacji Serwisowi poprzez wykasowanie plików cookies zapisanych na Twoich urządzeniach końcowych przez Serwis. Aby tego dokonać należy zmienić ustawienia aktualnie używanej przeglądarki internetowej.
</p><p>
Możliwe jest również skonfigurowanie przeglądarki w sposób, który zablokuje instalację plików cookie dla określonych, wybranych przez Ciebie stron internetowych lub dla wszystkich stron. Ustawienia takie spowodują jednak utratę niektórych funkcjonalności Serwisu, które wymagają instalacji plików cookie. Zgodnie z wymogami ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne za potwierdzenie zgody na wykorzystanie plików cookies uznaje się skonfigurowanie przeglądarki pozwalające na instalację plików cookies na komputerze.
</p>
<br/>
<p>
W celu zadania pytania, przekazania uwag dotyczących niniejszej Polityki prywatności lub skorzystania ze swoich praw w zakresie ochrony danych osobowych prosimy o przesłanie wiadomości na adres e-mail <a href="mailto:daneosobowe@leanpassion.pl">daneosobowe@leanpassion.pl</a>. W celu weryfikacji tożsamości Użytkownika możemy prosić o podanie dodatkowych informacji pozwalających nam Państwa uwierzytelnić.<br/>
</p><p>
W przypadku jeśli uważają Państwo, że przetwarzanie przez nas Państwa danych osobowych narusza przepisy prawa proszę nas o tym poinformować. Możecie wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.<br/>
</p>
<p>
Zastrzegamy sobie prawo zmiany Polityki Prywatności.
</p>
`,
}
export default content
