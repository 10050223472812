<template>
  <section class="site__section section--companies">
    <h3 class="section__companies-title">
      {{ title }}
    </h3>
    <div class="section__companies-container">
      <div
        v-for="(item, i) of list"
        :key="`company-${i}`"
        class="section__companies-item"
      >
        <div class="section__companies-image">
          <img :src="`companies/${item.image}`" alt="" />
        </div>
        <div class="section__companies-name">
          {{ item.name }}
        </div>
        <div class="section__companies-description">
          {{ item.description }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionCompanies',
  props: {
    title: {
      type: String,
      default: '',
    },
    logos: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.section {
  &--companies {
    margin: 2rem auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
  }
  &__companies {
    &-title {
      margin: 2rem 0;
      text-align: center;
      font-size: var(--font-size-title-xl);
      font-weight: 700;

      @media screen and (max-width: 768px) {
        line-height: 1.3;
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      padding: 1rem 3rem;
      column-gap: 2rem;
      row-gap: 3rem;
      flex-wrap: wrap;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 0;
        column-gap: 0;
        row-gap: 3rem;
      }
    }

    &-item {
      flex: auto;
      max-width: 28%;

      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }

    &-image {
      border-radius: var(--border-radius-sm);
      overflow: hidden;

      img {
        max-width: 100%;
        display: block;
      }
    }

    &-name,
    &-description {
      font-size: var(--font-size-sm);
      color: var(--color-text);
      text-align: center;
      font-weight: 500;
      line-height: 1.5;
      display: block;
      padding: 0;
    }
    &-description {
      padding: 0.5rem 1rem 0.25rem;
      line-height: 1.3;
    }

    &-name {
      padding: 1rem 0 0;
      font-size: var(--font-size-lg);
      font-weight: 600;
    }
  }
}
</style>
