const content = {
  title: 'Warunki korzystania',
  text: `
  <p>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis eget venenatis eros. Donec eget congue justo. Nullam malesuada tellus porttitor tellus bibendum, eu suscipit leo dictum. Morbi quis odio risus. Vivamus nec tincidunt quam, ut luctus mi. Maecenas fringilla elit id purus volutpat congue. Integer volutpat commodo vehicula. Curabitur venenatis consectetur velit, nec venenatis ex vulputate in. Praesent odio lectus, congue in dolor sed, condimentum efficitur lorem. Proin non volutpat arcu.
  </p><p>
  Vestibulum viverra eget erat eget rutrum. Fusce semper, arcu a bibendum euismod, tellus ex cursus ligula, et laoreet risus mi vel libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Cras euismod neque in venenatis pretium. Maecenas a lacus in nisi tristique egestas gravida sit amet est. Sed nec leo a risus molestie malesuada.
  </p><p>
  Vivamus condimentum vulputate risus eget efficitur. Nulla urna sapien, feugiat ac dictum sed, commodo eu lacus. In hac habitasse platea dictumst. Nulla ullamcorper venenatis gravida. Donec lacinia quam et enim maximus cursus. Pellentesque et aliquet orci. Mauris sit amet risus lectus. Duis sollicitudin, mi lacinia volutpat imperdiet, massa arcu hendrerit nisl, quis convallis mauris nisl id orci. Vestibulum at ultrices urna. Suspendisse aliquam mauris sit amet justo accumsan varius. Sed nulla dui, egestas non varius ut, commodo ac ligula. Vivamus lacinia magna risus, ac bibendum enim commodo at. Duis sagittis feugiat dui, in tristique ligula porta vitae. Mauris accumsan hendrerit nisl vel faucibus. Donec diam tortor, pretium commodo convallis eu, semper in odio. Duis vitae vestibulum metus.
  </p><p>
  Nunc at venenatis tortor. Mauris dapibus libero neque, ac accumsan est lobortis eleifend. Praesent non cursus libero, eu facilisis velit. Nullam eget tortor ut arcu eleifend ullamcorper. Etiam nulla urna, dapibus ac dignissim id, fermentum sit amet ligula. In a malesuada odio. Vestibulum rutrum laoreet lorem a lacinia. Praesent eleifend risus augue, eget egestas ex semper ac. Proin arcu nunc, vulputate sit amet lectus ut, malesuada rutrum eros. Pellentesque cursus est ipsum, a lacinia dolor pharetra at. Ut consectetur sagittis erat. Integer fringilla velit turpis, sit amet tincidunt neque viverra sed. Duis sed odio egestas, vestibulum dui non, pharetra mi. Curabitur ligula arcu, luctus ut velit et, viverra venenatis leo. Vivamus eu dapibus lacus. Aliquam in est eros.
  </p><p>
  Phasellus volutpat gravida ligula egestas pellentesque. Curabitur lectus sem, tempor quis mattis at, ornare ut dolor. Mauris faucibus sit amet ante id lacinia. Duis malesuada turpis enim, eget mollis mauris fringilla vel. Quisque placerat vitae sem vel volutpat. Morbi faucibus ex enim, a tempor odio commodo quis. Proin commodo, libero nec feugiat euismod, libero sem ornare elit, eu tincidunt mi quam posuere urna. Nulla sit amet ex at metus fermentum dictum vel sed dui. Nulla vitae condimentum justo, in venenatis enim. Proin at feugiat lacus.
  </p>`,
}
export default content
