<template>
  <div class="site__container">
    <MainHeader />
    <SectionHeadline :headline="webinar.headline" :lead="webinar.lead" />
    <section class="site__section section--intro">
      <div class="section__video">
        <div class="section__video-photo">
          <img src="photos/radek-drzewiecki-intro.jpg" alt="Radek Drzewiecki" />
        </div>
        <div class="section__video-title">
          <!--div class="section__video-time">
            <img src="@/assets/icons/icon-clock.png" alt="O" />
            <strong>{{ webinar.video_time }}</strong>
          </div-->
          <span>
            {{ webinar.video_subtitle }}
          </span>
        </div>
      </div>
      <div class="section__form">
        <FormReservation formName="formIntro" />
      </div>
    </section>
    <SectionIcons :icons="webinar.icons" />
    <section class="site__section section--details">
      <div v-for="(icon, i) of webinar.icons" :key="`icons-${i}`">
        {{ icon.description }}
      </div>
    </section>
    <SectionSummary
      :summary="webinar.summary"
      :video="webinar.video"
      :poster="webinar.summary.image"
    />
    <SectionSpeakers
      :title="webinar.speakers.title"
      :list="webinar.speakers.list"
    />
    <SectionCompanies
      :title="webinar.companies.title"
      :logos="webinar.companies.logos"
      :list="webinar.companies.list"
    />
    <section class="site__section section--outro">
      <h3 class="section__outro-headline">
        Gotowy, aby dowiedzieć się, jak ograniczyć rotację pracowników i
        zwiększyć efektywność?
      </h3>
      <div class="section__outro">
        <div class="section__outro-photo">
          <img src="photos/radek-drzewiecki-show.jpg" alt="Radek Drzewiecki" />
        </div>
        <div class="section__outro-title">
          Radek Drzewiecki, Founder Leanpassion
        </div>
      </div>
      <div class="section__form">
        <FormReservation formName="formOutro" />
      </div>
    </section>
    <MainFooter />
  </div>
</template>

<script>
import webinar from '@/models/landing-example'

import MainHeader from '@/components/MainHeader'
import MainFooter from '@/components/MainFooter'
import SectionHeadline from '@/components/SectionHeadline'
// import VideoPlayer from '@/components/VideoPlayer'
import FormReservation from '@/components/FormReservation'
import SectionIcons from '@/components/SectionIcons'
import SectionSummary from '@/components/SectionSummary'
import SectionSpeakers from '@/components/SectionSpeakers'
import SectionCompanies from '@/components/SectionCompanies'
// import SectionCredentials from '@/components/SectionCredentials'

export default {
  name: 'MainView',

  data() {
    return {
      webinar,
    }
  },

  components: {
    MainHeader,
    MainFooter,
    SectionHeadline,
    // VideoPlayer,
    FormReservation,
    SectionIcons,
    SectionSummary,
    SectionSpeakers,
    SectionCompanies,
    // SectionCredentials,
  },
}
</script>

<style lang="scss">
.section {
  &--intro {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 1rem 2rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__video {
    flex: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      justify-content: center;
      flex: 1;
      align-items: center;
      color: var(--color-text-light);
      font-size: var(--font-size);
      font-weight: 500;

      @media screen and (max-width: 768px) {
        padding-top: 1rem;
        text-align: center;
      }

      span {
        flex: 1;
        text-align: center;
        line-height: 1.3;
      }
    }

    &-time {
      display: flex;
      background: var(--color-text-light);
      color: var(--color-bg);
      align-items: center;
      padding: 0.5rem 0.75rem;
      border-radius: var(--border-radius-sm);
      opacity: 0.5;

      img {
        height: 1.5rem;
        margin-right: 0.5rem;
      }
      strong {
        font-weight: 500;
        white-space: nowrap;
      }
    }

    .video--bigger {
      box-shadow: var(--box-shadow);
    }

    &-photo {
      flex: 1;
      border-radius: var(--border-radius);
      max-width: 100%;
      min-height: 87%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &--outro {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 3rem 3rem;
    border-radius: var(--border-radius-xl);
    background: var(--color-bg-alt);
    flex-wrap: wrap;
    margin-top: 3rem;

    @media screen and (max-width: 768px) {
      padding: 2rem;
    }
  }
  &__outro {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 35rem;

    &-headline {
      flex: 1 100%;
      font-size: var(--font-size-title-xl);
      font-weight: 800;
      color: var(--color-text);
      display: block;
      padding: 1rem 5rem 4rem;
      margin: 0;
      text-align: center;
      line-height: 1.2;

      @media screen and (max-width: 768px) {
        padding: 1rem 1rem 3rem;
      }
    }

    &-title {
      display: flex;
      justify-content: center;
      flex: 1;
      align-items: center;
      color: var(--color-text-light);
      font-size: var(--font-size);
    }

    &-photo {
      flex: 1;
      border-radius: var(--border-radius);
      max-width: 100%;
      min-height: 90%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  &__form {
    flex: 1;
    min-width: 26rem;
    max-width: 26rem;
    padding-left: 2rem;

    @media screen and (max-width: 768px) {
      min-width: 100%;
      max-width: 100%;
      padding: 2rem 0;
    }
  }

  &--details {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    column-gap: 2rem;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    > div {
      margin: 0;
      flex: 1;
      max-width: 33%;
      line-height: 1.7;
      padding: 0 1rem;
      font-size: var(--font-size);

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding-bottom: 1rem;
      }
    }
  }
}
</style>
