<template>
  <section class="site__section section--speakers">
    <h3 class="section__speakers-title">
      {{ title }}
    </h3>
    <div
      v-for="(item, i) of list"
      :key="`person-${i}`"
      class="section__speakers-item"
    >
      <div class="section__speakers-photo">
        <img :src="item.image" :alt="item.name" />
      </div>
      <div class="section__speakers-person">
        <h4 class="section__speakers-name">
          {{ item.name }}
        </h4>
        <h5 class="section__speakers-position">
          {{ item.position }}
        </h5>
        <div class="section__speakers-bio" v-html="item.bio" />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SectionSpeakers',
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.section {
  &--speakers {
    padding: 2rem 2rem 3rem;
    background: var(--color-bg-alt);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    border-radius: var(--border-radius-xl);
  }

  &__speakers {
    &-title {
      color: var(--color-text);
      text-align: center;
      font-size: var(--font-size-title-xl);
      font-weight: 700;
    }

    &-item {
      display: flex;
      margin: 1rem auto;
      padding: 1rem 3rem;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 1rem 0;
      }

      & + & {
        margin-top: 3rem;

        @media screen and (max-width: 768px) {
          margin-top: 1rem;
        }
      }
    }

    &-photo {
      flex: 1;
      border-radius: var(--border-radius);
      max-width: 45%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 42rem;
      position: relative;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        max-height: 30rem;
        align-items: stretch;
      }

      img {
        display: block;
        max-width: unset;
        max-height: 100%;
      }
    }

    &-person {
      flex: 1;
      max-width: 55%;
      padding-left: 3rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        padding: 2rem 1rem 0rem;
      }
    }

    &-name {
      font-size: var(--font-size-title-xl);
      font-weight: 700;
      color: var(--color-main);
      margin: 0;
      padding: 0;
    }
    &-position {
      margin: 0;
      padding: 0;
      font-size: var(--font-size-xl);
      font-weight: 700;
      color: var(--color-text-light);
    }

    &-bio {
      padding: 2rem 0 0;
      font-size: var(--font-size-sm);
      line-height: 1.75;

      @media screen and (max-width: 768px) {
        font-size: var(--font-size);
      }
    }
  }
}
</style>
