<template>
  <section class="site__section" :class="className">
    <h1 v-if="headline" v-html="headline" class="section__headline" />
    <h2 v-if="lead" v-html="lead" class="section__lead" />
  </section>
</template>

<script>
export default {
  name: 'SectionHeadline',
  props: {
    className: {
      type: String,
      default: 'section--welcome',
    },
    headline: {
      type: String,
      default: null,
    },
    lead: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
.section {
  &--welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 2rem;

    @media screen and (max-width: 768px) {
      text-align: left;
    }
  }

  &__headline {
    margin: 1rem auto 2rem;
    padding: 0;
    font-size: var(--font-size-title-xxl);
    font-weight: 700;
    line-height: 1.3;
    max-width: 80%;

    small {
      display: block;
      padding-bottom: 0.5rem;
    }

    em {
      font-style: normal;
      color: var(--color-main);
    }

    @media screen and (max-width: 768px) {
      max-width: 100%;

      br {
        display: none;
      }
    }
  }

  &__lead {
    margin: 1rem auto;
    padding: 0;
    font-size: var(--font-size-xl);
    font-weight: 400;
    line-height: 1.75;
    max-width: 90%;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      line-height: 1.6;
    }
  }
}
</style>
