const API_URL = process.env.VUE_APP_API_URL
const WEBINAR_ID = process.env.VUE_APP_WEBINAR_ID
const CALENDAR_API = process.env.VUE_APP_ICAL_URL

const POST = async function (object = {}) {
  // eslint-disable-next-line
  const { path, ...data } = object
  const request = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  const url = `${API_URL}${path}`
  const response = await fetch(url, {
    ...request,
  })
  return response
}

const GET = async function (path) {
  const url = `${API_URL}${path}`
  const response = await fetch(url)
  return response
}

export { POST, GET, WEBINAR_ID, CALENDAR_API }
